<template>
  <div class="w-100">
    <vue3-chart-js ref="chartRef" v-if="chart" :height="350" v-bind="{ ...chart }" />
  </div>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import moment from 'moment'

export default {
  name: 'order-graph',
  props: {
    order: {
      type: Object,
      required: true,
    },
    dataPointLimit: {
      type: Number,
      default: () => {
        return 30;
      }
    }
  },
  components: {
    Vue3ChartJs
  },
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    getChartData: {
      deep:true,
      handler() {
        if (this.getChartData?.datasets) {
          this.updateChartData()
        }
      },
    },
  },
  computed: {
    statsString() {
      let property = 'viewers_history';
      for(const s of ['viewers_history_minute','viewers_history_hour','viewers_history_day']){
        if (this.order[s]) {
          property = s;
          break;
        }
      }
      return property;
    },
    graphView() {
      return this.order.type === 2 || this.order.type === 3 ? '2' : '1'
    },
    getStatsDateString() {
      if (!this.order || this.order[this.statsString].length==0 || !this.order[this.statsString][0]) {
        return 0;
      }
      return this.order[this.statsString][0]['date_minute'] ? 'date_minute' : this.order[this.statsString][0]['date_hour'] ? 'date_hour' : this.order[this.statsString][0]['date_day'] ? 'date_day' : ''
    },
    getChartData() {
      if (this.order[this.statsString]) {
        return {
          labels: this.getLabels(this.order[this.statsString]),
          datasets: this.getDatasets(),
        }
      }
      return {labels:[],datasets:[]};
    },
  },
  methods: {
    updateChartData() {
      this.chart.data.labels = this.getChartData.labels
      this.chart.data.datasets = this.getChartData.datasets;
      try{
        this.$refs.chartRef.update(250);
        // eslint-disable-next-line
      } catch {
      }
    },
    getLabels(stats) {
      if (!stats || stats.length === 0) {
        return [];
      }
      let dateAttr = stats[0]['date_minute'] ? 'date_minute' : stats[0]['date_hour'] ? 'date_hour' : stats[0]['date_day'] ? 'date_day' : '';
      return this.limitItems(stats.map(s => {
          return moment(s[dateAttr]).format('DD/MM hh:mm');
      }));
    },
    getDatasets() {
      let stats = [];

      if (this.graphView == 1) {
        stats.push({ //redirects line
          type:'line',
          label: this.getTrans("messages.total_so_far"),
          borderColor: '#dc6c8e',
          backgroundColor: 'rgb(220, 108, 142)',
          data: this.createTotals(this.order[this.statsString].map(s => {
            return s['redirects'];
          })),
          xAxisID:"x",
          yAxisID:"y_line1",
        });
        stats.push({ //redirects bars
          type: 'bar',
          label: this.getTrans("messages.delivered_popups"),
          borderColor: '#4d96d1',
          backgroundColor: 'rgba(77, 150, 209, .4)',
          borderWidth: 2,
          data: this.limitDataPoints(this.order[this.statsString].map(s => {
            return s['redirects'];
          })),
          xAxisID:"x",
          yAxisID:"y_bar1",
        });
      } else {
        stats.push({ //clicks line/total
          type:'line',
          label: this.getTrans("messages.total_clicks"),
          borderColor: '#fcc803',
          backgroundColor: 'rgb(252, 200, 3)',
          data: this.createTotals(this.order[this.statsString].map(s => {
            return s['clicks'];
          })),
          xAxisID:"x",
          yAxisID:"y_line2",
        });
        stats.push({ //impressions line
          type:'line',
          label: this.getTrans("messages.total_ad_views"),
          borderColor: '#dc6c8e',
          backgroundColor: 'rgb(220, 108, 142)',
          data: this.createTotals(this.order[this.statsString].map(s => {
            return s['impressions'];
          })),
          xAxisID:"x",
          yAxisID:"y_line3",
        });
        stats.push({ //clicks bars
          type: 'bar',
          label: this.getTrans("messages.clicks"),
          borderColor: '#fcc803',
          backgroundColor: 'rgba(252, 200, 3, .4)',
          borderWidth: 2,
          data: this.limitDataPoints(this.order[this.statsString].map(s => {
            return s['clicks'];
          })),
          xAxisID:"x",
          yAxisID:"y_bar2",
        });
        stats.push({ //impressions bars
          type: 'bar',
          label: this.getTrans("messages.ad_views"),
          borderColor: '#4d96d1',
          backgroundColor: 'rgba(77, 150, 209, .4)',
          borderWidth: 2,
          data: this.limitDataPoints(this.order[this.statsString].map(s => {
            return s['impressions'];
          })),
          xAxisID:"x",
          yAxisID:"y_bar3",
        });
      }

      return stats;
    },
    /**
     * makes an increasing array of numbers from a set of numbers by adding them together and limits the size to dataPointLimit
     */
    createTotals(numberArray) {
      let a = [...numberArray], b = [], total = 0;
      total+= Number.parseInt(a.shift());
      b.push(total);
      while(a.length) {
        total += this.totalArray(a.splice(0, Math.ceil(numberArray.length / (this.dataPointLimit - 1))));
        b.push(total);
      }
      return b;
    },

    /**
     *  returns array with set number of data points, adds the numbers onto the remaining values
     */
    limitDataPoints(array) {
      let a = [...array], b = [];
      b.push(Number.parseInt(a.shift()))
      while(a.length) {
        b.push(this.totalArray(a.splice(0, Math.ceil(array.length / (this.dataPointLimit - 1)))));
      }
      return b;
    },
    /**
     *  removes extra items, used for labels or data calculated elsewhere
     */
    limitItems(array) {
      let a = [...array], b = [];
      b.push(a.shift());
      while (a.length) {
        let temp = a.splice(0, Math.ceil(array.length / (this.dataPointLimit-1)));
        b.push(temp[temp.length-1]);
      }
      return b;
    },
    /** totalArray
     *  adds all numbers in array together
     */
    totalArray(array) {
      return array.reduce((partialSum, a) => Number.parseInt(partialSum) + Number.parseInt(a), 0);
    }
  },
  mounted() {
    this.chart = {
      id: 'linechart',
      type: '',
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: '#333',
              usePointStyle: true
            }
          },
        },
        stacked: false,
        elements: {
          line: {
            tension:0.4
          }
        },
        interaction: {
          mode: 'index',
          intersect: false,
        },
        responsive: true,
        maintainAspectRatio: false,

        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false
        },
        scales: {
          x: {
            gridLines: {
              drawBorder: false,
              fontColor: "#FFFFFF",
              zeroLineColor: "transparent",
              display: false,
            },
            ticks: {
              stepSize: Math.round((Math.max.apply(Math, this.getLabels) / 30)/5)*5,
            }
          },
          y_bar1: {
            gridLines: {
              drawBorder: false,
              display: false,
            },
            display:false,
            grid: {
              display: false
            }
          },
          y_bar2: {
            gridLines: {
              drawBorder: false,
              display: false,
            },
            display:false,
            grid: {
              display: false
            }
          },
          y_bar3: {
            gridLines: {
              drawBorder: false,
              display: false,
            },
            display:false,
          },
          y_line1: {
            gridLines: {
              drawBorder: false,
              display: false,
            },
            display:false,
            min:0,
          },
          y_line2: {
            gridLines: {
              drawBorder: false,
              display: false,
            },
            display:false,
            min:0,
          },
          y_line3: {
            gridLines: {
              drawBorder: false,
              display: false,
            },
            display:false,
            min:0,
          },
        },
        layout: {
          padding: 0
        }
      },
    };
    this.updateChartData();
  },
}
</script>
