<template>
  <span :class="'text-' + statusStyle">{{getTrans('messages.' + statusText)}}</span>
</template>

<script>

import ProfileStatusMixin from "@/mixins/Profile/ProfileStatusMixin";

export default {
  name: "profile-status",
  mixins: [ProfileStatusMixin],
};
</script>
