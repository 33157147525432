<script>
export default {
  methods: {
    orderNotFound() {
      this.invalidOrder(this.getTrans('messages.order_not_found'));
    },
    invalidOrder(message) {
      this.$swal.fire(
        message, '', 'warning').then(() => {
        this.orderRedirect();
      });
    },
    orderRedirect() {
      this.$router.push({name: 'orders'});
    },
  }
};
</script>
