<script>

export default {
  methods: {
    roomLimit(profile) {
      return profile.settings?.max_viewers || profile.defaultMaxMinViewers?.max_viewers;
    },
  },
};
</script>
