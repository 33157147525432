<template>
  <diva-card>
    <div class="row loader-container" v-if="loading">
      <div class="order-loader">
      <content-loader></content-loader>
      </div>
    </div>
    <div v-if="order" :class="{'loading-opacity':loading}">
      <div class="d-flex justify-content-between mb-4">
        <h2 class="page-title">{{order.title}}</h2>
        <h2 class="page-title">{{order?.plan?.data?.name || getTrans('messages.deleted_plan')}}</h2>
      </div>
      <div>{{getTrans('messages.total_traffic')}}</div>
      <div class="row" v-if="statuses[order.status] !== 'active'">
        <div class="col-12">
        <div class="d-flex justify-content-end w-100">
          <el-radio-group v-model="statsInterval" :disabled="loading">
            <el-radio-button label="minute" :disabled="!statsMinutesAvailable">{{getTrans('messages.filter_minute')}}</el-radio-button>
            <el-radio-button label="hour" :disabled="!statsHoursAvailable">{{getTrans('messages.filter_hour')}}</el-radio-button>
            <el-radio-button label="day">{{getTrans('messages.filter_day')}}</el-radio-button>
          </el-radio-group>
        </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12" v-if="isCurrentOrder">
          <OrderGraph :order="order"></OrderGraph>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="row align-items-end">
            <div class="col-12 col-sm-4 text-center text-sm-left order__progress-completion">{{getTrans('messages.spent_tokens', {
              'spent': usdToTokens(order.spent),
              'total': usdToTokens(order.paid)
            })}}</div>
            <div class="col-12 col-sm-4 my-2 my-sm-0">
              <component :is="endless(order) ? 'el-tooltip' : 'div'" placement="bottom" :content="getTrans('messages.renew_forever')">
                <div class="order__progress-status-text" :class="'bg-' + progressType(order)">
                  {{statusStr(order)}} <span v-if="showRenewals(order)" v-html="renewals(order)"></span>
                  <br/>
                  <span v-if="readyForRenew(order) && statuses[order.status] !== 'pending'">{{autoRenewIn(order)}}</span>
                </div>
              </component>
            </div>
            <div class="col-12 col-sm-4  text-center text-sm-right order__progress-completion">{{progressPercent(order)}}</div>
          </div>
          <base-progress
            :type="progressType(order)"
            :height="10"
            :value="progress(order)"
            :class="'w-100'"
            :progressClasses="'mb-0 mt-2'">
          </base-progress>
        </div>
      </div>
      <div class="row mt-3 justify-content-center">
        <div class="col-12 col-sm-4 text-center text-sm-left">
          <p class="order__popup-statistic">
            {{getTrans('messages.' + (order.type === 2 ? 'estimate_clicks' : 'estimate_popups'))}}
            <strong>{{estimatedTraffic}}</strong>
          </p>
        </div>
        <div class="col-12 col-sm-4 my-3 my-sm-0 text-center">
          <OrderActions v-if="order" :order="order" :showStats="false"></OrderActions>
        </div>
        <div class="col-12 col-sm-4  text-center text-sm-right">
          <p class="order__popup-statistic">
            {{getTrans('messages.' + (order.type === 2 ? 'delivered_clicks' : 'delivered_popups'))}}
            <strong>{{deliveredTraffic}}</strong>
          </p>
        </div>
      </div>
      <div class="orders-table__container mt-3 mt-sm-0 mt-lg-3" role="table">
        <div class="orders-table__row-group header" :class="[order.type === 2 ? 'order-statistic__native' : 'order-statistic']" role="row-group">
          <div class="orders-table__row" role="column-header">{{ getTrans('messages.destination') }}</div>
          <div class="orders-table__row" role="column-header">{{ getTrans('messages.status') }}</div>
          <div class="orders-table__row" role="column-header" v-if="order.type === 1">{{ getTrans('messages.delivered_popups') }}</div>
          <div class="orders-table__row" role="column-header" v-if="order.type === 2">{{ getTrans('messages.ad_views') }}</div>
          <div class="orders-table__row" role="column-header" v-if="order.type === 2">{{ getTrans('messages.clicks') }}</div>
          <div class="orders-table__row" role="column-header">{{ getTrans('messages.room_limit') }}</div>
          <div class="orders-table__row" role="column-header">{{ getTrans('messages.online_viewers') }}</div>
        </div>
        <div v-for="profile in profiles" :key="profile.id" class="orders-table__row-group" :class="[order.type === 2 ? 'order-statistic__native' : 'order-statistic']" role="rowgroup">
          <div class="orders-table__row order-statistic">
            <profiles-avatar :profiles="[profile]"></profiles-avatar>
          </div>
          <div class="orders-table__row">
            <p class="orders-table__cell-name small">
              <ProfileStatus :profile="profile"></ProfileStatus>
            </p>
          </div>
          <div class="orders-table__row" v-if="order.type === 1">
            <div class="orders-table__progress-status" :class="'text-primary'">{{profilesStatistic[profile.id]?.redirects}}</div>
          </div>
          <div class="orders-table__row" v-if="order.type === 2">
            <div class="orders-table__progress-status" :class="'text-primary'">{{profilesStatistic[profile.id]?.impressions}}</div>
          </div>
          <div class="orders-table__row" v-if="order.type === 2">
            <div class="orders-table__progress-status" :class="'text-primary'">{{profilesStatistic[profile.id]?.clicks}}</div>
          </div>
          <div class="orders-table__row">
            <p class="orders-table__cell-name small">{{roomLimit(profile)}}</p>
          </div>
          <div class="orders-table__row">
            <p class="orders-table__cell-name small">{{order.viewers[profile.id]?.viewers}}</p>
          </div>
        </div>
      </div>
      <div class="row mt-3 justify-content-center">
        <base-button @click="toOrders" type="secondary">{{ getTrans('messages.back_to_orders') }}</base-button>
        <base-button @click="toStats" type="primary">{{ getTrans('messages.advanced_stats') }}</base-button>
        <base-button v-if="statuses[order.status] === 'completed' " @click="renew" type="success">{{ getTrans('messages.renew_order') }}</base-button>
      </div>
    </div>
  </diva-card>
</template>

<script>
import {mapGetters} from 'vuex';
import BaseProgress from "@/components/BaseProgress.vue";
import OrderStatus from "@/views/Orders/Mixins/OrderStatus";
import OrderErrors from "@/views/Orders/Mixins/OrderErrors";
import OrderActions from "@/views/Orders/Components/OrderActions";
import ProfilesAvatar from "@/views/Orders/Components/ProfilesAvatar";
import StringFormatter from "@/mixins/StringFormatter";
import PlanUtilMixin from '@/mixins/Plan/PlanUtilMixin';
import ProfileStatus from "@/views/Profiles/Components/ProfileStatus";
import ProfileViewersMixin from "@/mixins/Profile/ProfileViewersMixin";
import OrderGraph from "./OrderGraph.vue"
import {ElRadioGroup, ElRadioButton} from 'element-plus'
import moment from 'moment'

export default {
  name: 'order-stats',
  mixins: [OrderStatus, OrderErrors, StringFormatter, ProfileViewersMixin, PlanUtilMixin],
  components: {
    BaseProgress,
    OrderActions,
    ProfilesAvatar,
    ProfileStatus,
    OrderGraph,
    ElRadioGroup,
    ElRadioButton,
  },
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      profilesStatistic: {},
      statsInterval: null,
      deliveredTraffic: 0,
    }
  },
  watch: {
    statsInterval: {
      handler(newVal, oldVal) {
        if (oldVal !== null) {
          this.update();
        }
      },
    }
  },
  computed: {
    viewers_history() {
      if (this.statsInterval && this.order && this.statuses[this.order.status]!='active') {
        return 'viewers_history_' + this.statsInterval;
      } else {
        return 'viewers_history'
      }
    },
    ...mapGetters('Order', {
      order: 'getData',
      loading: 'loading',
      statuses: 'statuses'
    }),
    profiles() {
      return this.order?.profilesLimitsList?.data;
    },
    estimatedTraffic() {
      if (this.order) {
        return Math.round(this.estimatedTrafficPerToken(this.order.plan.data, this.order.bid_percent) * this.order.budget);
      }
      return '-'
    },
    duration() {
      let duration = 0;
      if (this.order) {
        let created = moment(this.order.created_at);
        let end;
        if (this.order.completed_at) {
          end = moment(this.order.completed_at);
        } else if (this.statuses[this.order.status] !== 'active') {
          end = moment(this.order.updated_at);
        } else {
          end = moment();
        }
        duration = end.diff(created);
      }
      return duration;
    },
    statsMinutesAvailable() {
      return this.duration <= (moment().add(1, 'days') - moment());
    },
    statsHoursAvailable() {
      return this.duration <= (moment().add(31, 'days') - moment());
    },
    isCurrentOrder() {
      if (this.order?.id == this.orderId) {
        return true;
      }
      return false;
    }
  },
  methods: {
    update() {
      if (!this.orderId) {
        this.orderNotFound();
      }
      return this.$store.dispatch('Order/getById', {id: this.orderId, include: ['viewers', this.viewers_history, 'profilesStatistic', 'profilesLimitsList', 'plan', 'profiles', 'profilesId']}).then(() => {
        this.deliveredTraffic = 0;
        this.profilesStatistic = {};
        this.order.profilesStatistic.forEach(item => {
          this.deliveredTraffic += parseInt(this.order.type === 2 ? item.clicks : item.redirects);
          this.profilesStatistic[item.profile_id] = item;
        });
        if (!this.isActive(this.order)) {
          clearTimeout(this.timeout);
        }
      });
    },
    toOrders() {
      this.$router.push({ name: "orders" });
    },
    toStats() {
      this.$router.push({ name:'statistics',
        query: {
          filter: JSON.stringify({
            groupBy:"date",
            custom:[{value: this.order.id, dimIndex:2}],
          }),
        }
      })
    },
    renew() {
      this.$store.dispatch('Order/renew', this.order.id);
    },
    setStatsInterval() {
      if (this.isActive(this.order)) {
        this.statsInterval = null;
      } else if (this.statsMinutesAvailable) {
        this.statsInterval = 'minute';
      } else if (this.statsHoursAvailable) {
        this.statsInterval = 'hour';
      } else {
        this.statsInterval = 'day';
      }
    }
  },
  mounted() {
    this.update().then(()=>{
      this.$nextTick(()=>{
        
        this.setStatsInterval();
        this.timeout = setTimeout(() => {
          this.update();
        }, 60000);
      })
    });
    
  },
  unmounted(){
    clearTimeout(this.timeout);
  },
}

</script>
<style scoped>
.loder-container {
  min-height: 200px;
}
.order-loader {
  padding-top: 150px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
}
.loading-opacity {
  opacity: .5;
}
</style>
